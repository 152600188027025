<template>
	<div class="lock-container">
		<div class="lock-form animated">
			<div class="animated" :class="{ shake: passwdError, bounceOut: hasPass }">
				<el-input
					placeholder="请输入锁屏密码"
					type="password"
					class="input-with-select animated"
					v-model="passwd"
					@keyup.enter="handleLogin"
				>
					<template #append>
						<el-icon @click="handleLogin">
							<Unlock />
						</el-icon>
						<el-icon @click="handleLogout">
							<Right />
						</el-icon>
					</template>
				</el-input>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useAppStore } from '@/store/modules/app'
import { useUserStore } from '@/store/modules/user'
import { useTagStore } from '@/store/modules/tag'
import { ElMessageBox, ElMessage } from 'element-plus'

export default defineComponent({
	name: 'Lock',
	setup() {
		const appStore = useAppStore()
		const userStore = useUserStore()
		const tagStore = useTagStore()
		const router = useRouter()
		const state = reactive({
			passwd: '',
			passwdError: false,
			hasPass: false,
			handleLogout() {
				ElMessageBox.confirm('正在退出系统, 是否继续?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					userStore.logout()
					router.push({ path: '/login' })
				})
			},
			handleLogin() {
				if (state.passwd !== appStore.lockPasswd) {
					state.passwd = ''
					ElMessage({
						message: '解锁密码错误，请重新输入',
						type: 'error',
					})
					state.passwdError = true
					setTimeout(() => {
						state.passwdError = false
					}, 1000)
					return
				}
				state.hasPass = true
				setTimeout(() => {
					appStore.clearLock()

					const path = tagStore.visitedViews.filter(tag => tag.isActive)[0].path
					router.push({
						path,
					})
				}, 1000)
			},
		})

		return {
			...toRefs(state),
		}
	},
})
</script>

<style lang="less">
.lock-container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.title {
		margin-bottom: 8px;
		color: #333;
	}
	.el-icon {
		font-size: 18px;
		cursor: pointer;
		& + .el-icon {
			margin-left: 20px;
		}
	}
	.el-input__inner {
		height: 40px;
		line-height: 40px;
	}
}
.lock-container::before {
	z-index: -999;
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-image: url('https://websitedn.yiautos.com/erp3/img/bg/login.png');
	background-size: cover;
}
.lock-form {
	width: 300px;
}
.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}
@keyframes shake {
	0%,
	to {
		transform: translateZ(0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-10px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		transform: translate3d(10px, 0, 0);
	}
}
.shake {
	animation-name: shake;
}
.animated.bounceOut {
	animation-duration: 0.75s;
}
@keyframes bounceOut {
	20% {
		transform: scale3d(0.9, 0.9, 0.9);
	}

	50%,
	55% {
		opacity: 1;
		transform: scale3d(1.1, 1.1, 1.1);
	}

	to {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
}

.bounceOut {
	animation-name: bounceOut;
}
// TODO 这块样式后续可以删
.el-overlay.is-message-box .el-overlay-message-box {
	text-align: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.el-message-box {
	--el-messagebox-title-color: var(--el-text-color-primary);
	--el-messagebox-width: 420px;
	--el-messagebox-border-radius: 4px;
	--el-messagebox-font-size: var(--el-font-size-large);
	--el-messagebox-content-font-size: var(--el-font-size-base);
	--el-messagebox-content-color: var(--el-text-color-regular);
	--el-messagebox-error-font-size: 12px;
	--el-messagebox-padding-primary: 15px;
	display: inline-block;
	width: var(--el-messagebox-width);
	padding-bottom: 10px;
	vertical-align: middle;
	background-color: var(--el-color-white);
	border-radius: var(--el-messagebox-border-radius);
	border: 1px solid var(--el-border-color-lighter);
	font-size: var(--el-messagebox-font-size);
	box-shadow: var(--el-box-shadow-light);
	text-align: left;
	overflow: hidden;
	backface-visibility: hidden;
}
.el-message-box__header {
	position: relative;
	padding: var(--el-messagebox-padding-primary);
	padding-bottom: 10px;
}
.el-message-box__title {
	padding-left: 0;
	margin-bottom: 0;
	font-size: var(--el-messagebox-font-size);
	line-height: 1;
	color: var(--el-messagebox-title-color);
}
.el-message-box__headerbtn {
	position: absolute;
	top: var(--el-messagebox-padding-primary);
	right: var(--el-messagebox-padding-primary);
	padding: 0;
	border: none;
	outline: none;
	background: transparent;
	font-size: var(--el-message-close-size, 16px);
	cursor: pointer;
}
.el-message-box__headerbtn .el-message-box__close {
	color: var(--el-color-info);
	font-size: inherit;
}
.el-icon {
	--color: inherit;
	height: 1em;
	width: 1em;
	line-height: 1em;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	fill: currentColor;
	color: var(--color);
	font-size: inherit;
}
.el-icon svg {
	height: 1em;
	width: 1em;
}
.el-message-box__content {
	padding: 10px var(--el-messagebox-padding-primary);
	color: var(--el-messagebox-content-color);
	font-size: var(--el-messagebox-content-font-size);
}
.el-message-box__container {
	position: relative;
}
.el-message-box__status {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 24px !important;
}
.el-message-box__status.el-message-box-icon--warning {
	--el-messagebox-color: var(--el-color-warning);
	color: var(--el-messagebox-color);
}
.el-message-box__message {
	margin: 0;
}
.el-message-box__status + .el-message-box__message {
	padding-left: 36px;
	padding-right: 12px;
	word-break: break-word;
}
.el-message-box__btns {
	padding: 5px 15px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
}
.el-message-box__btns button:nth-child(2) {
	margin-left: 10px;
}
</style>
